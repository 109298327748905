/* Global CSS */

@font-face {
		font-family: 'Josefin Sans';
		src: url('./assets/font/JosefinSans-Bold.ttf') format('ttf'),
				 url('./assets/font/JosefinSans-Bold.WOFF') format('woff');
		font-weight: 700;
		font-style: normal;
}

@font-face {
		font-family: 'Good OT';
		src: url('./assets/font/GoodOT-WideMedium.ttf') format('ttf'),
				 url('./assets/font/GoodOT-WideMedium.WOFF') format('woff');
		font-weight: 500;
		font-style: normal;
}

@font-face {
		font-family: 'Roboto';
		src: url('./assets/font/Roboto-Black.ttf') format('ttf'),
				 url('./assets/font/Roboto-Black.WOFF') format('woff');
		font-weight: 900;
		font-style: normal;
}

@font-face {
		font-family: 'Roboto';
		src: url('./assets/font/Roboto-Bold.ttf') format('ttf'),
				 url('./assets/font/Roboto-Bold.WOFF') format('woff');
		font-weight: 700;
		font-style: normal;
}

@font-face {
		font-family: 'Roboto';
		src: url('./assets/font/Roboto-Medium.ttf') format('ttf'),
				 url('./assets/font/Roboto-Medium.WOFF') format('woff');
		font-weight: 500;
		font-style: normal;
}

@font-face {
		font-family: 'Roboto';
		src: url('./assets/font/Roboto-Regular.ttf') format('ttf'),
				 url('./assets/font/Roboto-Regular.WOFF') format('woff');
		font-weight: 400;
		font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
		font-family: 'Roboto';
		font-size: 16px;
		background-color:#002144;
}

.navigationMenu {
	display: flex;
	justify-content: center;
	height: 120px;
	border-bottom-style: solid;
	border-bottom-color: #F0F0F0;
}

@media screen and (min-width: 900px) {
	.navigationMenu {
		display: flex;
		justify-content: center;
		height: 160px;
		border-bottom-style: solid;
		border-bottom-color: #F0F0F0;
	}
}
	@media screen and (min-width: 720px) {
		.navigationMenu {
			height: 160px;
		}
	}

	.navigationMenuContents {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0px auto 0 0;
		flex-direction: column;
	}

.navigationMenu__logo {
	height: 120px;
	margin-top: 10px;
}

	@media screen and (min-width: 900px) {
		.navigationMenu__logo {
			height: 160px;
			margin-top: 30px;
		}
	}

	.ML__logo {
		height: 116px;
		margin-top: 50px;
	}

	.pin__header {
		font-family: Roboto;
		text-transform: uppercase;
		font-size: 24px;
		margin: 20px auto 0 auto;
		color: white;
		text-align: center;
	}

	@media screen and (min-width: 1200px) {
		.pin__header {
			font-family: Roboto;
			text-transform: uppercase;
			font-size: 28px;
			margin: 20px auto 0 auto;
			color: white;
		}
	}

	.navigationMenu__logo img {
		height: 100%;
	}

	.contentContainer {
		width: 100%;
		margin: auto;
	}

	.contentContainerTop {
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media screen and (min-width: 1200px) {
		.contentContainerTop {
			width: 100%;
			margin: auto;
			display: flex;
			flex-direction: row;
		}
	}

	.contentContainerBottom {
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media screen and (min-width: 1200px) {
		.contentContainerBottom {
			width: 100%;
			margin: auto;
			display: flex;
			flex-direction: row;
		}
	}

	.app--enterPin {
		position: relative;
		width: 100%;
		background-position-x: 73vw;
		background-position-y: 240px;
		background-repeat: no-repeat;
		background-image: url("assets/img/ML_Can_Blurry.png");
		background-size: 360px;
	}

	.contentContainer--viewport {
		min-height: calc(100vh - 260px);
	}

	.contentContainer--viewportFull {
		min-height: 100vh;
	}

	.langSwitcher {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		right: 16px;
		margin: auto;
		font-family: 'Good OT';
		font-size: 14px;
		font-weight: 500;
	}

	@media screen and (min-width: 720px) {
		.langSwitcher {
			right: 75px;
			font-size: 16px;
		}
	}

	.langSwitcher__item {
		text-decoration: none;
	}

		.langSwitcher__item:first-of-type {
			margin-right: 7px;
			color: #1B365D;
		}

		.langSwitcher__item:last-of-type {
			color: #005690;
		}

	.langSwitcher--fr .langSwitcher__item:first-of-type {
		color: #005690;
	}

	.langSwitcher--fr .langSwitcher__item:last-of-type {
		color: #1B365D;
	}

	.langSwitcher--molsonExport .langSwitcher__item:first-of-type {
		color: #AB9D62;
	}

	.langSwitcher--molsonExport .langSwitcher__item:last-of-type {
		color: #fff;
	}

	.langSwitcher--molsonExport.langSwitcher--fr .langSwitcher__item:first-of-type {
		color: #fff;
	}

	.langSwitcher--molsonExport.langSwitcher--fr .langSwitcher__item:last-of-type {
		color: #AB9D62;
	}

	.celebrate {
		width: 80px;
		margin: 30px auto 10px auto;
	}

	@media screen and (min-width: 1100px) {
		.celebrate {
			margin: 0 auto 0 auto;
		}
	}

	.footer {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	@media screen and (min-width: 1100px) {
		.footer {
			height: 330px;
		}
	}

	.disclaimertext {
		color: #fff;
		margin: 30px auto 0 auto;
		font-size: small;
		max-width: 800px;
	}

	@media screen and (min-width: 1100px) {
		.disclaimertext {
			margin: 0 auto 50px auto;
		}
	}

	.footerContents {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 1100px;
		padding: 24px 0;
	}

	@media screen and (min-width: 1100px) {
		.footerContents {
			padding: 0;
			flex-direction: row;
			text-align: center;
			margin: 0 auto 0 auto;
		}
	}

	.footerSocials {
		display: flex;
		justify-content: space-between;
		list-style: none;
		padding: 0;
		margin: 20px auto 0 auto;
	}

	@media screen and (min-width: 1100px) {
		.footerSocials {
			margin: 0 auto 0 60px;
		}
	}


	.footerSocials__item {
		margin-right: 18px;
	}

		.footerSocials__item:last-of-type {
			margin-right: 0;
		}

		.footerSocials__item a {
			display: block;
		}

			.footerSocials__item a img {
				width: 24px;
			}

	.footerLinks {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 92%;
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.footerLinks {
			flex-direction: row;
			justify-content: space-between;
			text-align: left;
		}
	}

	.footertext {
		color: #fff;
		margin: 0px auto 30px auto;
	}

	@media screen and (min-width: 1100px) {
		.footertext {
			margin: 0 auto 0 0;
		}
	}


	.footerLinks__item {
		margin-bottom: 12px;
	}

		.footerLinks__item:last-of-type {
			margin-bottom: 0;
		}

	@media screen and (min-width: 720px) {
		.footerLinks__item {
			margin-right: 24px;
			margin-bottom: auto;
			padding-right: 24px;
			border-right: 1px solid #fff;
		}
	}

	.footerLinks__item:last-of-type {
		margin-right: 0;
		padding-right: 0;
		border-right: none;
	}

	.footerLinks__item a {
		color: #fff;
		text-decoration: none;
		font-weight: 700;
	}

	.textGoldConf {
		color: #AE8422;
		font-size: 28px;
		font-family: Roboto;
		font-weight: bolder;
		margin-right: auto;
		margin-bottom: 20px;
	}

	.textWhiteConf {
		color: #FFFFFF;
		font-size: 24px;
		font-family: Roboto;
		font-weight: bolder;
		margin-right: auto;
		margin-bottom: 20px;
	}

	.textGold {
		color: #AE8422;
		font-size: 36px;
		font-family: Roboto;
		font-weight: bolder;
	}

	@media screen and (min-width: 1200px) {
		.textGold {
			color: #AE8422;
			font-size: 36px;
			font-family: Roboto;
			font-weight: bolder;
			margin-right: auto;
		}
	}

	@media screen and (min-width: 1520px) {
		.textGold {
			font-size: 48px;
			font-family: Roboto;
			font-weight: bolder;
			margin-right: auto;
		}
	}

	@media screen and (min-width: 1200px) {
		.textWhite {
			color: #FFFFFF;
			font-size: 36px;
			font-family: Roboto;
			font-weight: bolder;
			margin-right: auto;
		}
	}

	.textWhite {
		color: #FFFFFF;
		font-size: 36px;
		font-family: Roboto;
		font-weight: bolder;
	}

	.textColorWhite {
		color: #fff;
	}

	.textColorBlue {
		color: #1B365D;
	}

	.textColorDarkBlue {
		color: #005690;
	}

	.textColorGrey {
		color: #787C81;
	}

	.backgroundColorRed {
		background: #5bb8e7;
	}

	.backgroundColorBlue {
		background: #1B365D;
	}

	.backgroundColorWhite {
		background: #FFFFFF;
	}

	.backgroundColorExportRed {
		background: #BA0C2F !important;
	}

	.backgroundColorExportPink {
		background: #651C32 !important;
	}

	.backgroundColorExportGold {
		background: #AB9D62 !important;
	}

	.textColorExportPink {
		color: #651C32 !important;
	}

	.textColorExportGold {
		color: #AB9D62 !important;
	}

	.shaderWrap {
		position: relative;
	}

		.shaderWrap:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: #000000;
			opacity: 0.75;
			top: 0;
		}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
		text-align: center;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
		text-align: center;
	}

	.required .label:after {
		content: " *";
		color: #E8474C;
		font-weight: normal;
		font-size: 0.75em;
		vertical-align: top;
	}

	.form input {
		width: 100%;
		padding: 16px;
		border-radius: 12px;
		border: 1px solid rgb(69 91 99 / .08);
		box-sizing: border-box;
		box-shadow: 0 0 1em 0.2em rgb(69 91 99 / .08);
		text-align: center;
		text-align-last: center;
		font-size: 16px;
		font-weight: 700;
	}

	.form select {
		width: 100%;
		padding: 16px 8px;
		border-radius: 12px;
		border: 1px solid rgb(69 91 99 / .08);
		box-sizing: border-box;
		box-shadow: 0 0 1em 0.2em rgb(69 91 99 / .08);
		text-align: center;
		text-align-last: center;
		color: rgb(120 132 158 / 0.56);
		font-size: 16px;
		font-weight: 700;
	}

		.form select option {
			color: rgb(120 132 158);
		}

		.form input::placeholder,
		.form select::placeholder {
			color: rgb(120 132 158 / 0.56);
			font-size: 16px;
			font-weight: 700;
		}

	.btn {
		display: block;
		width: 100%;
		text-align: center;
		border-width: 2px;
		font-size: 16px;
		border-radius: 12px;
		box-sizing: border-box;
		padding: 19px 8px;
		background: #002144;
		border-color: white;
		color: #fff;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 700;
		cursor: pointer;
	}

	.btn--grey {
		background: #787C81;
	}

	.lgl_text_small {
		color: white;
		font-size: 12px;
		margin: auto;
	}

		.lgl_text_small a {
			color: white;
			font-size: 12px;
			font-weight: bold;
			margin: auto;
			text-decoration: none;
		}

			.lgl_text_small a:visited {
				color: white;
				font-size: 12px;
				font-weight: bold;
				margin: auto;
				text-decoration: none;
			}

	@-webkit-keyframes hvr-push {
		50% {
			-webkit-transform: scale(0.9);
			transform: scale(0.9);
		}

		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	@keyframes hvr-push {
		50% {
			-webkit-transform: scale(0.9);
			transform: scale(0.9);
		}

		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	.hvr-push {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	}

		.hvr-push:hover, .hvr-push:focus, .hvr-push:active {
			-webkit-animation-name: hvr-push;
			animation-name: hvr-push;
			-webkit-animation-duration: 0.3s;
			animation-duration: 0.3s;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
			-webkit-animation-iteration-count: 1;
			animation-iteration-count: 1;
		}


	/* Modal */

	.modal {
		position: absolute;
		width: 92%;
		max-width: 520px;
		top: 48px;
		left: 0;
		right: 0;
		margin: auto;
		background: #F3F3F3;
		text-align: center;
		border-radius: 12px;
		overflow: hidden;
		box-shadow: 0 0 1em 0.2em rgb(0 0 0 / 0.1);
		z-index: 1;
	}

	@media screen and (min-width: 720px) {
		.modal {
			top: 17vw;
		}
	}

	.modal--shippingConfirm {
		position: relative;
		top: initial;
	}

	.modalTitle__title {
		font-family: 'Josefin Sans';
		font-weight: 700;
		color: #fff;
		margin: 0 auto;
		padding: 21px 8px;
	}

	.modalContents {
		padding: 12px;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.modalContents {
			padding: 24px;
		}
	}

	.eligibleNotice {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 13px;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.eligibleNotice {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-bottom: 13px;
			text-align: center;
		}

		.eligibleNotice--fr {
			flex-direction: column;
			margin-bottom: 24px;
		}
	}

	.eligibleNotice__text {
		margin: 12px auto;
		font-weight: 900;
	}

	@media screen and (min-width: 720px) {
		.eligibleNotice__text {
			margin: initial;
			font-weight: 900;
		}
	}

	.eligibleNotice__logo {
		width: 100px;
		margin: auto;
		padding: 0;
	}

	@media screen and (min-width: 720px) {
		.eligibleNotice__logo {
			width: 100px;
			margin: 0;
			padding: 0 9px;
		}

		.eligibleNotice--fr .eligibleNotice__logo {
			padding: 9px 0;
		}
	}

	.mathNotice {
		color: #78849E;
		font-weight: 500;
	}

	.modalContents__invalidPin {
		text-transform: uppercase;
		font-weight: 900;
	}

	.modalContents__invalidPinInstructions {
		width: 80%;
		margin: auto;
		color: #aaa;
		font-weight: 700;
	}

	.modalContents__support {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 32px;
	}

	.modalCustomerInfo {
		color: #484D54;
	}

		.modalCustomerInfo span {
			display: block;
			font-weight: 900;
			text-transform: uppercase;
		}

	.modalCustomerInfo__email {
		padding-top: 24px;
	}

	.modalContents__img {
		width: 70%;
		margin: auto;
		padding: 16px 0 12px 0;
	}

	.modalContents__productSelection {
		font-size: 20px;
	}

	@media screen and (min-width: 720px) {
		.modalContents__productSelection {
			font-size: 27px;
		}
	}

	.modalContents__copyright {
		display: block;
		margin: 0 auto 32px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.modalContents__copyright {
			padding: 0 32px;
		}
	}


	/* Contest Fork */

	.contestForkContainer {
		padding-top: 32px;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.contestForkContainer {
			padding-top: 64px;
		}
	}

	@media screen and (min-width: 1880px) {
		.contestForkContainer {
			padding-top: 3vw;
		}
	}

	.contestForkContainer__header {
		font-family: 'Josefin Sans';
		text-transform: uppercase;
		font-size: 27px;
		margin: 0 auto 16px auto;
	}

	@media screen and (min-width: 720px) {
		.contestForkContainer__header {
			font-size: 70px;
		}
	}

	.contestForkContainer__caption {
		font-family: 'Josefin Sans';
		text-transform: uppercase;
		font-size: 16px;
		margin: 0 auto 16px auto;
	}

	@media screen and (min-width: 720px) {
		.contestForkContainer__caption {
			font-size: 20px;
			margin: 0 auto 32px auto;
		}
	}

	.contestForkContainer__brand {
		display: block;
		font-weight: 500;
		margin-bottom: 48px;
	}

	.contestFork {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 92%;
		max-width: 700px;
		margin: auto;
	}

	@media screen and (min-width: 720px) {
		.contestFork {
			flex-direction: row;
		}
	}

	.contestForkOption {
		flex-basis: 48%;
		margin-bottom: 32px;
	}

	.contestForkOption__link {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 24px;
		box-shadow: 0 0 1em 0.2em rgb(69 91 99 / .08);
	}

	.contestForkOption__link {
		height: 240px;
		padding: 32px;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.contestForkOption__link {
			height: 100%;
			padding: 64px;
		}
	}

	.contestForkOption__link img {
		width: 100%;
		max-width: 260px;
	}

	@media screen and (min-width: 720px) {
		.contestForkOption__link img {
			max-width: 100%;
		}
	}

	.contestForkOption__caption {
		display: block;
		padding-top: 21px;
		font-weight: 500;
	}


	/* Age Gate */

	.langSwitcher--ageGate {
		position: relative;
		margin: 0 0 32px 0;
		padding-top: 32px;
	}

	@media screen and (min-width: 720px) {
		.langSwitcher--ageGate {
			position: relative;
			margin: 0 75px 64px 0;
		}
	}

	@media screen and (min-width: 1140px) {
		.langSwitcher--ageGate {
			position: relative;
			margin: 0 75px 0 0;
		}
	}

	@media screen and (min-width: 1880px) {
		.langSwitcher--ageGate {
			position: relative;
			margin: 0 75px 0 0;
			padding-top: 92px;
		}
	}

	.langSwitcher__item--ageGate:first-of-type {
		color: #1B365D;
	}

	.langSwitcher__item--ageGate:last-of-type {
		color: #78849E;
	}

	.langSwitcher--fr .langSwitcher__item--ageGate:first-of-type {
		color: #78849E;
	}

	.langSwitcher--fr .langSwitcher__item--ageGate:last-of-type {
		color: #1B365D;
	}

	.langSwitcher--molsonExport .langSwitcher__item--ageGate:first-of-type {
		color: #AB9D62;
	}

	.langSwitcher--molsonExport .langSwitcher__item--ageGate:last-of-type {
		color: #fff;
	}

	.langSwitcher--molsonExport.langSwitcher--fr .langSwitcher__item--ageGate:first-of-type {
		color: #fff;
	}

	.langSwitcher--molsonExport.langSwitcher--fr .langSwitcher__item--ageGate:last-of-type {
		color: #AB9D62;
	}

	.logos {
		text-align: center
	}

	.ageGateLogo {
		max-width: 250px;
		margin: 0 auto 48px auto;
	}

	@media screen and (min-width: 720px) {
		.ageGateLogo {
			max-width: 250px;
			margin: 0 auto 57px auto;
		}
	}

	.ageGateLogo2 {
		max-width: 200px;
		margin: 0 auto 48px auto;
	}

	@media screen and (min-width: 720px) {
		.ageGateLogo2 {
			max-width: 200px;
			margin: 0 20px 57px auto;
		}
	}

	.ageGateLogo3 {
		max-width: 0px;
		margin: 0 auto 0 auto;
	}

	@media screen and (min-width: 720px) {
		.ageGateLogo3 {
			max-width: 210px;
			margin: 30px auto 0 auto;
		}
	}

	.ageGateContainer {
		text-align: center;
	}

	.ageGateContainer__header {
		font-family: Roboto;
		text-transform: uppercase;
		font-size: 48px;
		margin: 70px auto 32px auto;
		color: white;
	}

	@media screen and (min-width: 720px) {
		.ageGateContainer__header {
			font-size: 48px;
		}
	}
.ageGateContainer__headergold {
	font-family: Roboto;
	text-transform: uppercase;
	font-size: 28px;
	margin: 0 auto 32px auto;
	color: #AE8422;
}

@media screen and (min-width: 720px) {
	.ageGateContainer__headergold {
		font-size: 23px;
	}
}
	.ageGateContainer__caption {
		font-family: 'Roboto';
		text-transform: uppercase;
		font-size: 18px;
		margin: 0 auto 24px auto;
		color: white;
	}

	@media screen and (min-width: 720px) {
		.ageGateContainer__caption {
			font-size: 18px;
			margin: 0 auto 24px auto;
		}
	}

	.form--ageGate {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-end;
		max-width: 420px;
		margin: auto;
	}

	.form--ageGate__province {
		flex-basis: 100%;
	}

	.form--ageGate__month,
	.form--ageGate__day,
	.form--ageGate__year {
		flex-basis: 30.33%;
		margin: 0;
	}

	.form--ageGate__btn {
		width: 100%;
	}


	/* Pin Page */
	.glove {
		display: flex;
		width: 320px;
		margin-top: 30px;
	}

	@media screen and (min-width: 1200px) {
		.glove {
			display: flex;
			max-width: 50%;
			width: 400px;
		}
	}



	@media screen and (min-width: 1200px) {
		.MLBpin {
			margin: auto;
		}
	}

	.MLB_Img {
		height: 80px;
	}

	.MLB {
		margin-left: auto;
		margin-right: auto;
	}

	@media screen and (min-width: 1200px) {
		.MLB {
			margin-left: 29%;
			margin-right: auto;
		}
	}

	.Rawlings {
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
	}

	@media screen and (min-width: 1200px) {
		.Rawlings {
			margin-left: auto;
			margin-right: 30%;
			margin-top: 0px;
		}
	}

	.Rawlings_Img {
		height: 80px;
	}

	.GoldTextImg {
		display: flex;
		max-width: 100%;
	}



	@media screen and (min-width: 1200px) {
		.Rawlingspin {
			margin-right: 33%;
		}
	}

	.Rawlingspin {
		margin: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.LogoAlign {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		margin-top: 30px
	}

	@media screen and (min-width: 1200px) {

		.LogoAlign {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			margin-top: 30px
		}
	}

	.FooterLogos {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 40px;
	}

	@media screen and (min-width: 1200px) {
		.FooterLogos {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 40px;
		}
	}



	.GoldText {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media screen and (min-width: 1200px) {
		.GoldText {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 50%;
		}
	}
.offerInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
	@media screen and (min-width: 1200px) {
		.offerInfo {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width:50%;
		}
	}
.ConfofferInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 1200px) {
	.ConfofferInfo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
	}
}

.confglove {

	width: 320px;
	margin-top: 30px;
}

@media screen and (min-width: 1200px) {
	.confglove {
		width: 400px;
	}
}

	.offerInfoRight {
		display: flex;
		flex-direction: column;
		padding: 30px 0 0 0;
		align-items: center;
		width: 80%;
		margin: auto;
		text-align: center;
	}

	@media screen and (min-width: 900px) {
		.offerInfoRight {
			display: flex;
			flex-direction: column;
			padding: 40px 40px 0 20px;
			align-items: center;
			width: 50%;
		}
	}

	.ConfInfoRight {
		display: flex;
		flex-direction: column;
		padding: 40px 40px 0 40px;
		align-items: center;
	}

	@media screen and (min-width: 1200px) {
		.ConfInfoRight {
			display: flex;
			flex-direction: column;
			padding: 40px 80px 0 20px;
			align-items: center;
			width: 50%;
		}
	}

	.Video {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: solid;
		border-color: white;
		border-width: 2px;
	}

	@media screen and (min-width: 1200px) {
		.Video {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 48%;
			border: solid;
			border-color: white;
			border-width: 2px;
		}
	}


	.offerInfofr {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background-image: url("assets/img/MUL_Q1_Website_Header_Mobilefr.png");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	@media screen and (min-width: 720px) {
		.offerInfofr {
			background-image: url("assets/img/MUL_Q1_Website_Headerfr.png");
		}
	}

	.offerInfo--fr {
		flex-direction: column;
	}

	.offerInfo__header {
		font-size: 20px;
		margin: auto 0;
		font-family: 'Josefin Sans';
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.offerInfo__header {
			font-size: 27px;
		}
	}

	.offerInfo__logo {
		width: 160px;
		padding: 12px 0;
	}

	@media screen and (min-width: 720px) {
		.offerInfo__logo {
			width: 1152px;
			padding: 0 18px;
		}

		.offerInfo--fr .offerInfo__logo {
			padding: 12px 0;
		}
	}

	.offerInfo__caption {
		flex-basis: 100%;
		font-size: 15px;
		margin: 32px auto 0 auto;
		font-family: 'Josefin Sans';
		text-transform: uppercase;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.offerInfo__caption {
			font-size: 18px;
			margin: 24px auto 0 auto;
		}
	}

	.productBlock {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-bottom: 32px;
	}

	@media screen and (min-width: 720px) {
		.productBlock {
			display: flex;
			flex-direction: row;
		}
	}

	.productBlockImageContainer {
		position: relative;
		flex-basis: 40%;
	}

		.productBlockImageContainer img {
			width: 100%;
			padding-top: 32px;
		}

	.productBlockImageContainer__sticker {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 140px;
		height: 140px;
		border-radius: 50%;
		top: 0;
		bottom: 0;
		margin: auto;
	}

		.productBlockImageContainer__sticker span {
			color: #fff;
			font-family: 'Josefin Sans';
			text-align: center;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 700;
			padding-top: 8px;
		}

	.productBlockImageContainer--fr .productBlockImageContainer__sticker span {
		font-size: 14px;
		line-height: 1.4;
	}

	.pinValidation {
		flex-basis: 60%;
		text-align: center;
	}

	.pinValidation__header {
		font-family: 'Josefin Sans';
		text-transform: uppercase;
		font-weight: 700;
		font-size: 42px;
		line-height: 1;
	}

	@media screen and (min-width: 720px) {
		.pinValidation__header {
			font-size: 42px;
		}

		.pinValidation--fr .pinValidation__header {
			font-size: 42px;
		}
	}


	.pinValidation__caption {
		font-family: 'Josefin Sans';
		display: block;
		margin: 0 auto 0px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.3;
		text-align: center;
		padding: 0;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.pinValidation__caption {
			padding: 0 64px;
		}
	}

	.form--pinValidation {
		width: 100%;
		box-sizing: border-box;
		text-align: left;
	}

	@media screen and (min-width: 1200px) {
		.form--pinValidation {
			padding: 0 30% 0 0;
			margin: 20px auto 0 0;
		}
	}

	.form--pinValidation__pin input {
		width: 100%;
		padding: 16px 8px;
		border-radius: 12px;
		border: 3px solid #005690;
		box-sizing: border-box;
		text-align: center;
		text-align-last: center;
		font-size: 16px;
		font-weight: 700;
	}

	.form--pinValidation__pin--molsonExport input {
		border-color: #651C32;
	}


	.form--pinValidation__pin input::placeholder {
		color: rgb(120 132 158 / 0.56);
		font-size: 16px;
		font-weight: 700;
	}


	/* Skill Test */

	.form--skillTest__question {
		font-size: 28px;
		font-weight: 900;
		margin: 24px auto 32px auto;
	}

	.form--skillTest__btn {
		margin-bottom: 12px;
	}

	.form--skillTest__copyright {
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}


	/* Product Selection */

	@media screen and (min-width: 720px) {
		.productBlock--productSelection {
			margin-top: 128px;
		}
	}

	@media screen and (min-width: 1400px) {
		.productBlock--productSelection {
			margin-top: auto;
		}
	}

	@media screen and (min-width: 1880px) {
		.productBlock--productSelection {
			margin-top: 128px;
		}
	}

	.productSelection {
		flex-basis: 100%;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.productSelection {
			flex-basis: 60%;
			padding: 0 48px;
		}
	}

	.jacketSelectionHeading {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-bottom: 32px;
		margin-top: 32px;
	}

	@media screen and (min-width: 720px) {
		.jacketSelectionHeading {
			flex-direction: column;
		}
	}

	.jacketSelectionHeading__header {
		font-size: 32px;
		line-height: 1.2;
		margin: auto 0;
		font-family: 'Josefin Sans';
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.jacketSelectionHeading__header {
			font-size: 32px;
		}
	}

	.jacketSelectionHeading__header:first-of-type {
		flex-basis: 100%;
	}

	.jacketSelectionHeading__logo {
		margin: auto;
		padding: 8px 0 12px 0;
	}

	@media screen and (min-width: 720px) {
		.jacketSelectionHeading__logo {
			margin: 0;
			padding: 0 9px;
		}
	}

	.form--productSelection {
		margin-bottom: 24px;
	}

		.form--productSelection select:active,
		.form--productSelection select:focus {
			border: 2px solid #1B365D;
			border-radius: 3px;
		}

	.productSelection__copyright {
		display: block;
		margin: 0 auto 32px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		padding: 0 16px;
		box-sizing: border-box;
	}


	/* Shipping Form Page & Confirm Info Page */

	.contentContainer--shippingForm {
		max-width: 768px;
		padding: 32px 0 64px 0;
	}

	.shaderWrap--shippingConfirm {
		padding: 64px 0;
	}

	.form--shippingForm {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-end;
		margin: auto;
	}

	.form--shippingForm__header {
		flex-basis: 100%;
		color: white;
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.form--shippingForm__header {
			font-size: 27px;
		}
	}

	.form--shippingForm__header--contact {
		margin-top: 48px;
	}

	.form--shippingForm__poNotice {
		display: block;
		color: #AE8422;
		font-size: 13px;
		font-weight: 700;
		margin-bottom: 6px;
		padding-left: 6px;
		box-sizing: border-box;
	}

	@media screen and (min-width: 720px) {
		.form--shippingForm__poNotice {
			font-size: 16px;
		}
	}

	.form--shippingForm__firstName {
		flex-basis: 49%;
	}

	.form--shippingForm__lastName {
		flex-basis: 49%;
	}

	.form--shippingForm__address {
		flex-basis: 100%;
		margin: 6px 0;
	}

	@media screen and (min-width: 720px) {
		.form--shippingForm__address {
			margin: 1em auto;
		}
	}

	.form--shippingForm__city,
	.form--shippingForm__province,
	.form--shippingForm__postal {
		flex-basis: 100%;
		margin: 6px 0;
	}

	@media screen and (min-width: 720px) {
		.form--shippingForm__city,
		.form--shippingForm__province,
		.form--shippingForm__postal {
			flex-basis: 32.33%;
			margin: 0;
		}
	}

	.form--shippingForm__emailNotice {
		background: #AE8422;
		color: #fff;
		font-size: 12px;
		font-weight: 700;
		padding: 5px 10px;
	}

	.form--checkbox {
		display: flex;
		flex-basis: 100%;
		width: 100%;
		align-items: flex-start;
		line-height: 1.4;
		color: white;
	}

		.form--checkbox input {
			width: auto;
			margin-right: 16px;
		}

	@media screen and (min-width: 720px) {
		.form--shippingForm__emailNotice {
			font-size: 13px;
			padding: 8px 12px;
		}
	}

	.form--shippingForm__email {
		flex-basis: 100%;
	}

		.form--shippingForm__email input {
			text-align: left;
			text-align-last: left;
		}

	.form--shippingForm__btn {
		width: 100%;
		max-width: 420px;
		margin: 1em auto;
	}

	.fieldError {
		display: block;
		font-size: 13px;
		margin: 1em 0;
		padding-left: 6px;
		box-sizing: border-box;
		color: #1B365D;
		font-weight: 500;
	}

	.contentContainer--shippingConfirm {
		position: absolute;
		left: 0;
		right: 0;
	}


	/* Confirmation Complete */

	.contentContainer--confirmationComplete {
		max-width: 520px;
	}

	.confirmationDetails {
		text-align: center;
	}

	.productSelectionImage__img {
		width: 100%;
		margin: auto;
	}

	.confirmationDetails__confirmationNumber {
		font-family: 'Josefin Sans';
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 auto 32px auto;
	}

	@media screen and (min-width: 720px) {
		.confirmationDetails__confirmationNumber {
			font-size: 27px;
		}
	}

	.confirmationDetails__thanks {
		font-size: 18px;
		font-weight: 900;
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.confirmationDetails__thanks {
			font-size: 20px;
		}
	}

	.confirmationDetails__notice {
		display: block;
		margin: 32px auto;
		font-weight: 900;
		text-transform: uppercase;
	}

	.confirmationDetails__text {
		font-weight: 900;
	}

	.confirmationDetails__logo {
		max-width: 240px;
		margin: 32px auto 48px auto;
	}

	@media screen and (min-width: 720px) {
		.confirmationDetails__logo {
			max-width: 320px;
		}
	}

	.confirmationDetails__copyright {
		display: block;
		margin: 0 auto 32px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		padding: 0 16px;
		box-sizing: border-box;
	}


	/* Contest Closed */

	.contestClosed {
		margin-top: 64px;
		text-align: center;
	}

	.contestClosed__header {
		font-family: 'Josefin Sans';
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.contestClosed__header {
			font-size: 27px;
		}
	}

	.contestClosed__logo {
		max-width: 160px;
		margin: auto;
	}

	@media screen and (min-width: 720px) {
		.contestClosed__logo {
			max-width: 280px;
		}
	}

	.contestClosed__link {
		display: inline-block;
		margin: 16px auto 32px auto;
		font-size: 20px;
		font-weight: 900;
		text-transform: uppercase;
	}

	@media screen and (min-width: 720px) {
		.contestClosed__link {
			margin: 48px auto;
			font-size: 27px;
		}
	}

	.contestClosed__copyright {
		display: block;
		margin: 0 auto 42px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		padding: 0 16px;
		box-sizing: border-box;
	}


	/* Email Confirmation */

	.contentContainer--emailConfirmation {
		max-width: 520px;
	}

	.emailContents {
		padding-top: 48px;
		text-align: center;
	}

	.emailContents__logo {
		max-width: 240px;
		margin: 0 auto 32px auto;
	}

	@media screen and (min-width: 720px) {
		.emailContents__logo {
			max-width: 280px;
		}
	}

	.emailContents__header {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 900;
	}

	.jacketSelectionHeading.jacketSelectionHeading--emailConfirmation {
		margin: 28px auto;
	}

	.emailContents__hello {
		font-size: 27px;
		margin-top: 64px;
	}

	.emailContents__text {
		font-size: 18px;
		line-height: 1.3;
		font-weight: 900;
	}

	.emailContents__title {
		font-size: 27px;
		text-transform: uppercase;
	}

	.emailContents__title--summary {
		margin: 64px auto 24px auto;
	}

	.emailContentsImageContainer {
		margin-bottom: 48px;
		border: 1px solid rgb(112 112 112 / .2);
		box-shadow: 0 0.16em 1em 0 rgba(0,0,0,0.15);
		padding: 32px 64px;
	}

	.emailContentsImageContainer__product {
		width: 100%;
		margin: auto;
	}

	.emailContents__faq {
		display: block;
		margin: 48px auto 32px auto;
		text-decoration: none;
		font-weight: 900;
	}

	.emailContents__copyright {
		display: block;
		margin: 32px auto;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		padding: 0 16px;
		box-sizing: border-box;
	}


	/* Terms of Service */

	.termsOfService {
		margin: 32px auto;
		padding: 0 50px 0 50px;
		color: white;
	}

	@media screen and (min-width: 720px) {
		.termsOfService {
			margin: 64px auto;
			color: white;
		}
	}

	.termsOfService__title {
		font-weight: 900;
	}


	/* FAQ */

	.faq {
		margin: 48px auto 0 auto;
		padding: 0 50px 50px 32px;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.faq {
			margin: 64px auto 0 auto;
			padding-bottom: 64px;
		}
	}

	.faq__title {
		font-family: 'Josefin Sans';
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 32px;
	}

	@media screen and (min-width: 720px) {
		.faq__title {
			font-size: 27px;
			margin-bottom: 72px;
		}
	}

	.faqEntry {
		font-size: 15px;
		margin-bottom: 24px;
	}

	@media screen and (min-width: 720px) {
		.faqEntry {
			font-size: 16px;
			margin-bottom: 48px;
		}
	}

	.faqEntry__question {
		font-weight: 900;
	}

	.faqEntry__answer {
		text-align: left;
	}

	@media screen and (min-width: 720px) {
		.faqEntry__answer {
			text-align: center;
		}
	}


	/* Contact Us */

	.contactInfo {
		width: 92%;
		margin: 64px auto auto auto;
		text-align: center;
	}

	@media screen and (min-width: 720px) {
		.contactInfo {
			position: absolute;
			margin: 128px auto auto auto;
			left: 0;
			right: 0;
		}
	}

	.contactInfo__title {
		font-family: 'Josefin Sans';
		font-size: 27px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 48px;
	}

	.contactInfo__text {
		font-weight: 900;
	}
